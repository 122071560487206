/* eslint-disable react/display-name */
import React from 'react'
import { useQuery } from 'react-apollo'

import { CardBody } from '@paljs/ui/Card'

import { GET_BY_SLUG } from '../../util/graphql/queries'

import Loading from '../../components/Loading'
import PostContent from '../../components/PostContent'

import Layout from '../../layout'

import NotFound from '../../pages/404'

import { CardStyled, ButtonStyled, CardHeaderStyled } from './styled'

const Index: React.FC = () => {
  const { data, loading } = useQuery(GET_BY_SLUG, { variables: { slug: 'start-here' } })
  const post = data?.posts[0]

  if (loading) return <Loading />
  if (!post) return <NotFound />
  return (
    <Layout seoTitle={post.title}>
      <PostContent item={post} hideHeader={true} />
      <CardStyled>
        <CardHeaderStyled>
          <h1>Want to share something?</h1>
          <p>You need to have access, ask you Teamlead or PM</p>
        </CardHeaderStyled>
        <CardBody>
          <a href='http://devrepo-admin.foundersworkshop.com/admin' target='__blank'>
            <ButtonStyled>Strapi Admin</ButtonStyled>
          </a>
        </CardBody>
      </CardStyled>
    </Layout>
  )
}

export default Index
