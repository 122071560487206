import React, { useEffect, useRef, useState } from 'react'

import { Card } from '@paljs/ui/Card'
import { Toastr, ToastrRef } from '@paljs/ui/Toastr'
import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'
import Tooltip from '@paljs/ui/Tooltip'

import Loading from '../../components/Loading'

import Layout from '../../layout'

import useAuth from '../../util/useAuth'
import * as api from '../../util/api'
import SearchControls, { OnSearchProps } from '../../components/Wrike/Summary/Search'
import NotFound from '../../components/Wrike/NotFound'

import { TitleCard, CardBodyStyled, CardHeaderStyled, MenuContainer, ErrorMessage } from './styled'
import { Button } from '@paljs/ui/Button'
import { Link } from 'gatsby'

import Summary from '../../components/Wrike/Summary/Summary'
import ISummary from '../../components/Wrike/Summary/ISummary'

export interface UserData {
  email: string
  fullName: string
  password?: string
  passwordConfirm?: string
  paymentRate?: number
  address?: string
  paymentMethod?: string
}

interface TotalHours {
  hours: number
  period?: string
  sendInvoiceOption?: boolean
}

const Profile: React.FC = () => {
  const { authState, dispatchExtra } = useAuth()
  const [loading, setLoading] = useState(true)
  const [showHistory, setShowHistory] = useState(false)
  const [totalHours, setTotalHours] = useState<TotalHours>({ hours: 0 })
  const [summaryData, setSummaryData] = useState<ISummary>()
  const [errorMessage, setErrorMessage] = useState('')
  const toastrRef = useRef<ToastrRef>(null)

  useEffect(() => {
    if (authState.extra.wrikeTotals) {
      setTotalHours({ hours: authState.extra.wrikeTotals.month })
      setLoading(false)
    } else getTotals()
  }, [])

  const getTotals = () => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    setLoading(true)

    api
      .timeCampGetTotals(authState.jwt, { month, year, includeThisWeek: true })
      .then((res) => {
        setTotalHours({ hours: res.data.month })
        dispatchExtra({ wrikeTotals: res.data })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onSearch: OnSearchProps = async (settings, setLoadingWrikeData) => {
    setLoadingWrikeData(true)
    setErrorMessage('')
    api
      .timeCampGetSummary(authState.jwt, settings)
      .then((summary) => {
        setTotalHours({ hours: summary.data.total, period: summary.data.date, sendInvoiceOption: settings.isCustom })
        setSummaryData(summary.data)
      })
      .catch((err) => setErrorMessage(err.response?.data?.message))
      .finally(() => setLoadingWrikeData(false))

    //TODO:
    //manage history
  }

  if (loading) return <Loading />
  return (
    <Layout seoTitle='Profile'>
      <Toastr ref={toastrRef} />
      <Row>
        <Col breakPoint={{ sm: 12 }}>
          <Card>
            <CardHeaderStyled>
              <TitleCard>
                <img src='/images/timecamp_logo.svg' style={{ width: 100 }} />
                <span className='title'>You have worked:</span>
                <span className='hours'>{`${totalHours.hours.toFixed(2)} ${totalHours.hours === 1 ? 'hour' : 'hours'}`}</span>
                <span className='month'>{totalHours.period || 'this month'}.</span>
              </TitleCard>
              {/* <Button size='Tiny' status='Info' onClick={() => setShowHistory(!showHistory)}>
                {showHistory ? 'Hide History' : 'Show History'}
              </Button> */}
            </CardHeaderStyled>
            <CardBodyStyled>
              {!showHistory && (
                <MenuContainer>
                  <div className={`userPaymentInfo${authState.user.paymentRate ? '' : ' error'}`}>
                    {!authState.user.paymentRate ? (
                      <span>
                        In order to send an invoice you must update your payment information.
                        <br />
                        <Link to='/profile'>Go to Profile</Link>
                      </span>
                    ) : (
                      <>
                        <span className='payment-rate'>
                          <b>Payment Rate:</b> {authState.user.paymentRate}
                        </span>
                        <div className='items'>
                          <Tooltip trigger='hint' placement='bottom' status='Info' content={authState.user.address}>
                            <span className='popover-item'>Address</span>
                          </Tooltip>
                          <Tooltip trigger='hint' placement='bottom' status='Info' content={authState.user.paymentMethod}>
                            <span className='popover-item'>Method</span>
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </div>
                  <SearchControls onSearch={onSearch} />
                </MenuContainer>
              )}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <Row>
                {showHistory && (
                  <Col breakPoint={{ sm: 12, md: 3 }}>
                    <div>Hola2</div>
                  </Col>
                )}
                {summaryData && (
                  <Col breakPoint={{ sm: 12, md: showHistory ? 9 : 12 }}>
                    <Summary data={summaryData} sendInvoiceOption={!totalHours.sendInvoiceOption && !!authState.user.paymentRate} />
                  </Col>
                )}
              </Row>
            </CardBodyStyled>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default Profile
