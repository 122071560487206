import React, { useEffect, useState } from 'react'
import { Router, RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import { Toastr } from '@paljs/ui/Toastr'

import useAuth from '../util/useAuth'

import Loading from '../components/Loading'

import Dashboard from '../admin/Dashboard'
import StartHere from '../admin/StartHere'
import Profile from '../admin/Profile'
import Posts from '../admin/Posts'
import Post from '../admin/Post'
import Invoice from '../admin/Invoice'
import Links from '../admin/Links'
import ThirdParties from '../admin/ThirdParties'
import ThirdParty from '../admin/ThirdParty'
import Files from '../admin/Files'
import NotFound from './404'

Toastr.defaultProps = {
  position: 'bottomEnd',
  status: 'Primary',
  duration: 5000,
  hasIcon: true,
  destroyByClick: true,
  preventDuplicates: false,
}

const Index = ({ location }) => {
  const [loading, setLoading] = useState(true)

  const { getUser } = useAuth()
  const redirect = location?.pathname

  useEffect(() => {
    getUser()
      .then(() => setLoading(false))
      .catch(() => navigate('/auth', { state: { redirect } }))
  }, [])

  if (loading) return <Loading isAuth />
  else
    return (
      <Router>
        <RouterPage path='/' component={<Dashboard />} />
        <RouterPage path='/start-here' component={<StartHere />} />
        <RouterPage path='/profile' component={<Profile />} />
        <RouterPage path='/links' component={<Links />} />
        <RouterPage path='/thirdparties' component={<ThirdParties />} />
        <ThirdParties path='/thirdparties/:page' />
        <ThirdParty path='/thirdparty/:slug' />
        <Links path='/links/:page' />
        <RouterPage path='/files' component={<Files />} />
        <Files path='/files/:page' />
        <RouterPage path='/posts' component={<Posts />} />
        <Posts path='/posts/:page' />
        <Post path='/post/:slug' />
        <RouterPage path='/invoice' component={<Invoice />} />
        <RouterPage default component={<NotFound />} />
      </Router>
    )
}

//TODO:
// change register to blocked when masive registers are done

// set avatar
// add relationship between user and post

// Extra changes:
// search option, filter by topic, by user
// comments on posts
// emails when new post is created

// know issue: https://github.com/gatsbyjs/gatsby/issues/16097 no solution for it yet [only solution is migrate to create-react-app]
// fix server issue related to forbiden issue and fix static pages not taking index https://fw-devview-gatsby.herokuapp.com/posts
const RouterPage = (props: { component: JSX.Element } & RouteComponentProps) => props.component

export default Index
