import React, { useRef, useState } from 'react'

import { Card } from '@paljs/ui/Card'
import { Toastr, ToastrRef } from '@paljs/ui/Toastr'

import * as api from '../../../util/api'
import useAuth from '../../../util/useAuth'

import PaymentForm, { OnSubmitProps, PaymentData } from './paymentForm'
import { Container } from './styled'

interface ContactData {
  id?: string
  firstName?: string
  lastName?: string
  avatarUrl?: string
  title?: string
}

interface WrikeAuthProps {
  paymentData: PaymentData
}

const WrikeAuth: React.FC<WrikeAuthProps> = ({ paymentData }) => {
  const { authState, getUser } = useAuth()
  const [wrikeLoading, setWrikeLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [removeConfirm, setRemoveConfirm] = useState(false)
  const [data, setData] = useState<ContactData>({})
  const toastrRef = useRef<ToastrRef>(null)

  const onSubmit: OnSubmitProps = ({ paymentRate, paymentMethod, address }) => {
    setLoading(true)

    api
      .setUserProfile(authState.jwt, { paymentRate, paymentMethod, address })
      .then(() => toastrRef.current.add('', 'Payment has been updated', { status: 'Success' }))
      .catch((error) => toastrRef.current.add(error.message, 'Uh-oh', { status: 'Danger' }))
      .finally(() => {
        getUser()
        setLoading(false)
      })
  }

  return (
    <Card style={{ height: '100%' }}>
      <Toastr ref={toastrRef} />
      <Container>
        <PaymentForm initialValues={paymentData} onSubmit={onSubmit} loading={loading} />
      </Container>
    </Card>
  )
}

export default WrikeAuth
