import React, { useState } from 'react'

import { Button } from '@paljs/ui/Button'
import { Card } from '@paljs/ui/Card'
import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'
import Quote from 'inspirational-quotes'

import Totals from '../../components/Wrike/Totals'
import Layout from '../../layout'
import { CardHeaderStyled, ButtonStyled, CardBodyStyled, Container, QuoteItem } from './styled'

const Index: React.FC = () => {
  const [randomQuote, setRandomQuote] = useState(Quote.getQuote())
  const handleNext = () => setRandomQuote({ ...Quote.getQuote() })

  return (
    <Layout seoTitle='Links'>
      <Container>
        <Row>
          <Col breakPoint={{ xs: 12, sm: 6, md: 6 }}>
            <Totals />
          </Col>
          <Col breakPoint={{ xs: 12, sm: 6, md: 6 }}>
            <Card>
              <QuoteItem>
                <h2>Inspirational Quote</h2>
                <div>
                  <p>{`"${randomQuote.text}"`}</p>
                  <span>{`- ${randomQuote.author}`}</span>
                </div>
                <Button type='button' size='Small' onClick={handleNext}>
                  Next
                </Button>
              </QuoteItem>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col breakPoint={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <CardHeaderStyled>
                <div>
                  <span>WRIKE</span>
                </div>
                <img src='/images/wrike-title-bg.jpg' width='100%' />
              </CardHeaderStyled>
              <CardBodyStyled>
                <a
                  href='https://www.wrike.com/workspace.htm?acc=2851865#path=folder&id=368548591&p=356019157&a=2851865&c=list&so=10&bso=10&sd=0&f=&st=space-356019157'
                  target='__blank'>
                  <ButtonStyled>WRIKE TRAINING</ButtonStyled>
                </a>

                <a href='https://www.wrike.com/workspace.htm?acc=2851865#path=request-forms&accountid=2851865&formid=242466' target='__blank'>
                  <ButtonStyled>
                    WRIKE-REQUEST <br />
                    ACCESS TO PROJECT
                  </ButtonStyled>
                </a>
              </CardBodyStyled>
            </Card>
          </Col>
          <Col breakPoint={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <CardHeaderStyled>
                <div>
                  <span>FOUNDERS WORKSHOP</span>
                </div>
                <img src='/images/SD-title-bg.jpg' width='100%' />
              </CardHeaderStyled>
              <CardBodyStyled>
                <a href='docs/Team-Member-Handbook_2022.pdf' target='__blank'>
                  <ButtonStyled>
                    TEAM MEMBER <br />
                    HANDBOOK
                  </ButtonStyled>
                </a>
                <a href='docs/Development-Best-Practices_2021.pdf' target='__blank'>
                  <ButtonStyled>
                    Development <br />
                    Best Practices
                  </ButtonStyled>
                </a>
                <a href='docs/User-Experience-Standards-Checklist_V1.1.pdf' target='__blank'>
                  <ButtonStyled>
                    User Experience
                    <br />
                    Standards
                  </ButtonStyled>
                </a>
                <a href='docs/Email-Management-Requirements_2021.pdf' target='__blank'>
                  <ButtonStyled>
                    Email <br />
                    Management
                  </ButtonStyled>
                </a>
              </CardBodyStyled>
            </Card>
          </Col>
          <Col breakPoint={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <CardHeaderStyled>
                <div>
                  <span>REQUEST</span>
                </div>
                <img src='/images/requests-title-bg.jpg' width='100%' />
              </CardHeaderStyled>
              <CardBodyStyled>
                <a href='https://www.wrike.com/workspace.htm?acc=2851865#path=request-forms&accountid=2851865&formid=240462' target='__blank'>
                  <ButtonStyled>REQUEST RESOURCES</ButtonStyled>
                </a>
                <a
                  href='https://www.wrike.com/form/eyJhY2NvdW50SWQiOjI4NTE4NjUsInRhc2tGb3JtSWQiOjI0Njg3OX0JNDcxOTg2NDIyNTY4MQkwY2I2NTQ2ZjgyNTMyNjU2OTk3MzBlYTVlYzgxNDM0OWNjNWMyN2VjZThlNDY3NGU2Mjk3Njk1MDJhMmI0N2Ix'
                  target='__blank'>
                  <ButtonStyled>
                    REQUEST FOR <br />
                    MORE WORK
                  </ButtonStyled>
                </a>
              </CardBodyStyled>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Index
