import React, { useEffect, useState } from 'react'
import { isAndroid, isMobile, isDesktop } from 'react-device-detect'
import { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'

import Row from '@paljs/ui/Row'
import Col from '@paljs/ui/Col'
import { Button } from '@paljs/ui/Button'
import Spinner from '@paljs/ui/Spinner'

import { Container, ButtonContainer, AccordionStyled, AccordionTitle, AccordionContainer, TitleCategory } from './SummaryStyled'
import { EvaIcon } from '@paljs/ui/Icon'
import { EvaIconOptions } from '@paljs/icons'

import { AccordionItem } from '@paljs/ui/Accordion'

import useAuth from '../../../util/useAuth'
import { summaryToDocumentConverter } from '../../../util/helpers'
import * as api from '../../../util/api'
import ISummary from './ISummary'

interface SummaryProps {
  data: ISummary
  sendInvoiceOption?: boolean
}
const iconOptions: EvaIconOptions = {
  width: '20px',
  animation: { type: 'pulse', infinite: true },
}

const getHourString = (hours: number) => `${hours.toFixed(2)} ${hours === 1 ? 'hour' : 'hours'}`
const Summary: React.FC<SummaryProps> = ({ data, sendInvoiceOption }) => {
  const { authState } = useAuth()
  const [loading, setLoading] = useState(false)
  const [fileBlob, setFileBlob] = useState<Blob>()
  const dataDocument = summaryToDocumentConverter(authState.user, data)

  const getDocument = () => api.timeCampGeneratePDF(authState.jwt, dataDocument)
  useEffect(() => {
    setFileBlob(undefined)
    setLoading(true)
    getDocument()
      .then((res: AxiosResponse) => setFileBlob(new Blob([res.data], { type: 'application/pdf' })))
      .finally(() => setLoading(false))
  }, [data])

  const onDownload = (isPreview: boolean) => () => {
    setLoading(true)
    const actionUrl = (fileUrl: string) => {
      const fileLink = document.createElement('a')
      fileLink.href = fileUrl
      if (isPreview) fileLink.target = '__blank'
      else fileLink.download = `FoundersWorkshop Invoice ${data.date}.pdf`

      fileLink.click()
    }

    if (fileBlob) {
      actionUrl(URL.createObjectURL(fileBlob))
      setLoading(false)
    } else
      setTimeout(() => {
        getDocument()
          .then((res: AxiosResponse) => {
            const blob = new Blob([res.data], { type: 'application/pdf' })
            const fileUrl = URL.createObjectURL(blob)
            actionUrl(fileUrl)
          })
          .finally(() => setLoading(false))
      }, 100)
  }

  const sendInvoice = () => {
    setLoading(true)
    api.timeCampSendEmailInvoice(authState.jwt, dataDocument).then(() => {
      setTimeout(() => {
        Swal.fire('Invoice Sent', 'Your invoice has been sent, you should receive an Email soon', 'success')
        setLoading(false)
      }, 500)
    })
  }

  return (
    <Container>
      <Row>
        <Col breakPoint={{ sm: 12 }}>
          <AccordionStyled>
            {data.projects.map((p, i) => (
              <AccordionItem
                key={i}
                uniqueKey={i}
                title={
                  <AccordionTitle>
                    <span className='title'>{p.title}</span>
                    <span className='hours'>{getHourString(p.total)}</span>
                  </AccordionTitle>
                }>
                <AccordionContainer>
                  {p.categories.map((cat, i) => (
                    <div key={i} className='category'>
                      <TitleCategory name={cat.name}>
                        <span className='name'>{cat.name}</span>
                        <span className='hours'>{getHourString(cat.total)}</span>
                      </TitleCategory>
                      {cat.tasks.map((task, i) => (
                        <div key={i} className='task'>
                          <div className='title'>
                            <span className='name'>{task.title}</span>
                            <span className='hours'>{getHourString(task.total)}</span>
                          </div>
                          {task.times.map((time, i) => (
                            <div key={i} className='time'>
                              <div className='title'>
                                <span className='date'>{time.date}</span>
                                <span className='name'>{time.description}</span>
                                <span className='hours'>{getHourString(time.time)}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </AccordionContainer>
              </AccordionItem>
            ))}
          </AccordionStyled>
        </Col>
        <Col breakPoint={{ sm: 12 }}>
          <ButtonContainer>
            {isMobile && (
              <Button size='Small' onClick={onDownload(isAndroid)} style={{ position: 'relative' }}>
                <EvaIcon name='download' options={iconOptions} />
                Download
              </Button>
            )}
            {isDesktop && (
              <>
                <Button size='Small' onClick={onDownload(true)} style={{ position: 'relative' }}>
                  <EvaIcon name='search' options={iconOptions} />
                  Preview
                </Button>
                <Button size='Small' onClick={onDownload(false)} style={{ position: 'relative' }}>
                  <EvaIcon name='download' options={iconOptions} />
                  Download
                </Button>
              </>
            )}
            {sendInvoiceOption && (
              <Button size='Small' onClick={sendInvoice} style={{ position: 'relative' }}>
                <EvaIcon name='email' options={iconOptions} />
                Send Invoice
              </Button>
            )}
            {loading && <Spinner status='Info' />}
          </ButtonContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default Summary
