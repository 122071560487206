import React from 'react'
import { Link } from 'gatsby'

import { EvaIcon } from '@paljs/ui/Icon'
import { Button } from '@paljs/ui/Button'

import { PagContainer, PagNumberGroup } from './styled'

const LinkCustom = (props) => {
  const redirect = props.to
  if (redirect) return <Link to={redirect}>{props.children}</Link>
  else return <React.Fragment>{props.children}</React.Fragment>
}

export default function index({ page, pages, itemName }) {
  return (
    <PagContainer>
      <LinkCustom to={page - 1 >= 1 ? `/${itemName}/${page - 1}` : undefined}>
        <Button status='Info' size='Tiny' disabled={page - 1 < 1}>
          <EvaIcon name='arrow-left-outline' />
        </Button>
      </LinkCustom>
      <PagNumberGroup>
        {[page - 2, page - 1, page, page + 1, page + 2].map((p, i) => {
          if (p >= 1 && p <= pages) {
            return (
              <LinkCustom to={p !== page ? `/${itemName}/${p}` : undefined} key={i}>
                <Button status={p === page ? 'Control' : 'Primary'} size='Small'>
                  {p}
                </Button>
              </LinkCustom>
            )
          }
        })}
      </PagNumberGroup>
      <LinkCustom to={page + 1 <= pages ? `/${itemName}/${page + 1}` : undefined}>
        <Button status='Info' size='Tiny' disabled={page + 1 > pages}>
          <EvaIcon name='arrow-right-outline' />
        </Button>
      </LinkCustom>
    </PagContainer>
  )
}
