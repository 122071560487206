import { gql } from 'apollo-boost'

export const GET_POSTS = gql`
  query($start: Int, $limit: Int) {
    posts(start: $start, limit: $limit, sort: "updated_at:DESC") {
      id
      title
      slug
      topic
      updated_at
      created_by {
        firstname
        lastname
      }
    }
    postsConnection {
      aggregate {
        totalCount
      }
    }
  }
`

export const GET_BY_SLUG = gql`
  query($slug: String!) {
    posts(where: { slug: $slug }) {
      id
      title
      content
      topic
      updated_at
      created_by {
        firstname
        lastname
      }
    }
  }
`
export const GET_THIRDPARTY_BY_SLUG = gql`
  query($slug: String!) {
    thirdParties(where: { slug: $slug }) {
      id
      title
      content
      topic
      updated_at
      created_by {
        firstname
        lastname
      }
    }
  }
`
export const GET_SHARED_LINKS = gql`
  query($start: Int, $limit: Int) {
    sharedLinks(start: $start, limit: $limit, sort: "updated_at:DESC") {
      id
      title
      description
      url
      updated_at
      created_by {
        firstname
        lastname
      }
    }
    sharedLinksConnection {
      aggregate {
        totalCount
      }
    }
  }
`
export const GET_THIRD_PARTIES = gql`
  query($start: Int, $limit: Int) {
    thirdParties(start: $start, limit: $limit, sort: "updated_at:DESC") {
      id
      title
      slug
      topic
      updated_at
      description
      created_by {
        firstname
        lastname
      }
    }
    thirdPartiesConnection {
      aggregate {
        totalCount
      }
    }
  }
`
export const GET_FILE_RESOURCES = gql`
  query($start: Int, $limit: Int) {
    fileResources(start: $start, limit: $limit, sort: "updated_at:DESC") {
      id
      title
      description
      files {
        ext
        name
        url
        formats
      }
      updated_at
      created_by {
        firstname
        lastname
      }
    }
    fileResourcesConnection {
      aggregate {
        totalCount
      }
    }
  }
`
