import styled from 'styled-components'
import { Accordion } from '@paljs/ui/Accordion'
import { stringToColor, isDark } from '../../../util/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const ButtonContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;

  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  button i {
    margin-left: -10px;
  }
  @media (max-width: 830px) {
    flex-direction: column;
    button {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
export const AccordionStyled = styled(Accordion)`
  header {
    background-color: #fff;
  }
`
export const AccordionTitle = styled.div`
  display: flex;
  background-color: #fff;
  flex: 1;
  .title {
    display: flex;
    flex: 1;
    color: #333;
    text-decoration: none;
    font-size: 20px;
    a {
      margin-right: 10px;
    }
  }
  .hours {
    margin-right: 20px;
    margin-left: 30px;
    font-size: 18px;
  }

  @media (max-width: 830px) {
    flex-direction: column;
  }
`
export const AccordionContainer = styled.div`
  .task {
    margin: 7px 0;
    & > .title {
      font-weight: bold;
      display: flex;
      padding: 5px 0 5px 10px;
      .name {
        display: flex;
        flex: 1;
        a {
          margin-left: 5px;
        }
      }

      @media (max-width: 830px) {
        flex-direction: column;
      }
    }
  }
  .time {
    & > .title {
      display: flex;
      padding: 0 0 0 20px;

      .date {
        margin-right: 5px;
        padding-top: 2px;
        font-size: 12px;
        font-weight: bold;
        /* background-color: red; */
      }
      .name {
        /* background-color: blue; */
        display: flex;
        flex: 1;
      }

      @media (max-width: 830px) {
        flex-direction: column;
      }
    }
  }
`
export const TitleCategory = styled.div<{ name: string }>`
  background-color: ${({ name }) => stringToColor(name)};
  color: ${({ name }) => (isDark(stringToColor(name)) ? '#fff' : '#000')};
  padding: 10px;
  font-weight: bold;
  display: flex;
  @media (max-width: 830px) {
    flex-direction: column;
  }
  .name {
    display: flex;
    flex: 1;
    font-size: 18px;
  }
  .hours {
    font-size: 16px;
  }
`
