import React, { useEffect, useRef, useState } from 'react'

import { Toastr, ToastrRef } from '@paljs/ui/Toastr'
import { EvaIcon } from '@paljs/ui/Icon'

import Spinner from '@paljs/ui/Spinner'
import { Button } from '@paljs/ui/Button'

import NotFound from '../NotFound'

import * as api from '../../../util/api'
import useAuth from '../../../util/useAuth'

import { CardStyled, ImageContainer, LoadingContainer, TotalsContainer, Separator } from './styled'

interface TotalsData {
  month: number
  week: number
}

const Index: React.FC = () => {
  const { authState, dispatchExtra } = useAuth()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<TotalsData>()
  const toastrRef = useRef<ToastrRef>(null)

  useEffect(() => {
    if (authState.extra.wrikeTotals) {
      setData(authState.extra.wrikeTotals)
      setLoading(false)
    } else getTotals()
  }, [])

  const getTotals = () => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    setLoading(true)

    api
      .timeCampGetTotals(authState.jwt, { month, year, includeThisWeek: true })
      .then((res) => {
        setData(res.data)
        dispatchExtra({ wrikeTotals: res.data })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <CardStyled>
      <Toastr ref={toastrRef} />
      <ImageContainer>
        <img src='/images/timecamp_logo.svg' width='450px' />
        <Button status='Basic' size='Tiny' title='Reload' onClick={getTotals}>
          <EvaIcon name='refresh-outline' />
        </Button>
      </ImageContainer>
      {loading ? (
        <LoadingContainer>
          <Spinner status='Info' size='Large' />
        </LoadingContainer>
      ) : (
        data && (
          <TotalsContainer>
            <h1>{"You've worked:"}</h1>
            <p className='month'>
              <span>{`${data.month.toFixed(2)} ${data.month === 1 ? 'hour' : 'hours'}`}</span> this month
            </p>
            <Separator />
            <p className='week'>
              <span>{`${data.week.toFixed(2)} ${data.week === 1 ? 'hour' : 'hours'}`}</span> this week
            </p>
          </TotalsContainer>
        )
      )}
    </CardStyled>
  )
}

export default Index
