import styled from 'styled-components'

export const TitleText = styled.h1`
  font-size: 30px;
  line-height: 1;
`
export const DescriptionText = styled.span`
  display: block;
  margin-top: 10px;
  height: 200px;
  font-size: 15px;
  font-weight: normal;
`
export const AuthorText = styled.span`
  float: left;
`
export const DateText = styled.span`
  margin-left: 10px;
`

export const FilesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  a {
    margin-left: 10px;
    display: block;
    width: 50px;
    height: 50px;
    & > div {
      position: relative;
      span {
        position: absolute;
        top: 16px;
        color: #fff;
        font-size: 10px;
        width: 100%;
        text-align: center;
      }
      & > div {
        width: 50px;
        height: 50px;
      }
    }
    img {
      border: 1px solid #aaa;
      width: 100%;
      height: 100%;
    }
  }
`
