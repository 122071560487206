import Select from '@paljs/ui/Select'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const SelectStyled = styled(Select)`
  width: 250px;
  margin-right: 20px;
`
export const TextStyled = styled.p`
  font-size: 14px;
  margin: 0;
  color: #333;
`
