import styled from 'styled-components'

import { CardBody, CardHeader } from '@paljs/ui/Card'

export const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 350px;

  img {
    width: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #333;
  }
  .hours {
    line-height: 1;
    font-size: 50px;
    padding-left: 40px;
  }
  .month {
    text-align: right;
  }

  @media (max-width: 430px) {
    width: 100%;

    .title {
      font-size: 15px;
    }
    .hours {
      font-size: 40px;
      padding-left: 20px;
    }
  }
`
export const CardBodyStyled = styled(CardBody)`
  overflow: unset;
`
export const CardHeaderStyled = styled(CardHeader)`
  position: relative;
  button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  @media (max-width: 820px) {
    flex-direction: column;

    .userPaymentInfo {
      width: 100%;
      max-width: 100% !important;
      margin-bottom: 20px;
    }
  }

  .userPaymentInfo {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 5px;
    border-radius: 5px;
    background-color: rgba(1, 1, 1, 0.1);
    border: 1px dashed #eee;

    &.error {
      background-color: rgb(255 0 0 / 17%);
      border: 1px dashed red;
      justify-content: center;
      span {
        font-size: 13px;
        line-height: 1;
      }
    }
    .payment-rate {
      font-size: 14px;
      margin-bottom: 5px;
      margin-left: 5px;
      display: block;
    }
    .items {
      display: flex;

      .popover-item {
        font-size: 13px;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        display: block;
        background-color: rgb(1 9 253 / 50%);
        padding: 5px 10px;
        margin-left: 5px;
      }
    }
  }
`

export const ErrorMessage = styled.span`
  color: red;
`
