/* eslint-disable react/display-name */
import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { EvaIcon } from '@paljs/ui/Icon'
import { CardBody, CardHeader } from '@paljs/ui/Card'
import { Card } from '@paljs/ui/Card'
import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'

import { GoBackText, TitleText, AuthorText, TopicText, DateText, Container, ContentBox } from './styled'

const renderers = {
  code: ({ language, value }) => (
    <SyntaxHighlighter style={materialOceanic} language={language}>
      {value}
    </SyntaxHighlighter>
  ),
}

export interface PostItem {
  title: string
  topic: string
  content: string
  created_by: {
    firstname: string
    lastname: string
  }
  updated_at: Date
}

const Index: React.FC<RouteComponentProps<{ item: PostItem; backLink: string; hideHeader: boolean }>> = ({ item, backLink, hideHeader = false }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <GoBackText name={item.topic}>
              {backLink && (
                <Link to={backLink}>
                  <EvaIcon name='arrow-left-outline' />
                  Go back
                </Link>
              )}
            </GoBackText>
            <CardHeader>
              <TitleText>{item.title}</TitleText>
            </CardHeader>
            <CardBody>
              {!hideHeader && (
                <Row>
                  <Col>
                    <AuthorText>
                      <EvaIcon name='person' />
                      {`${item.created_by.firstname} ${item.created_by.lastname}`}
                    </AuthorText>
                    <TopicText name={item.topic}>{item.topic}</TopicText>
                  </Col>
                  <Col>
                    <EvaIcon name='calendar-outline' />
                    <DateText>{moment(item.updated_at).format('LL')}</DateText>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <ContentBox>
                    <ReactMarkdown
                      className='post-content'
                      linkTarget='_blank'
                      source={item.content}
                      allowDangerousHtml={true}
                      renderers={renderers}
                      plugins={[[gfm, { singleTilde: false }]]}
                    />
                  </ContentBox>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Index
