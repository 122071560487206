import styled from 'styled-components'

export const LoadingContainer = styled.div`
  margin-top: 20px;
  padding: 40px 20px 40px 20px;
  position: relative;
`
export const ImageContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
`
export const Container = styled.div`
  padding: 20px;
`
export const UserIDInfo = styled.div`
  span {
    font-size: 13px;
    display: block;
    margin-bottom: 15px;
  }
`
export const Separator = styled.div`
  background-color: blue;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #ddd;
`
export const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
`
export const ConfirmContainer = styled.div`
  span {
    display: block;
    text-align: right;
  }
`
