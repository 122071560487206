/* eslint-disable react/display-name */
import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from 'react-apollo'

import { GET_BY_SLUG } from '../../util/graphql/queries'

import Loading from '../../components/Loading'

import Layout from '../../layout'

import NotFound from '../../pages/404'

import PostContent from '../../components/PostContent'

const Index: React.FC<RouteComponentProps<{ slug: string }>> = ({ slug }) => {
  const { data, loading } = useQuery(GET_BY_SLUG, { variables: { slug } })
  const post = data?.posts[0]

  if (loading) return <Loading />
  if (!post) return <NotFound />
  return (
    <Layout seoTitle={post.title}>
      <PostContent item={post} backLink='/posts' />
    </Layout>
  )
}

export default Index
