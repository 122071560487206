import styled from 'styled-components'
import { CardHeader, CardBody } from '@paljs/ui/Card'
import { Button } from '@paljs/ui/Button'

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0 !important;
  position: relative;
  h1 {
    padding: 20px;
    padding-bottom: 0;
  }
  p {
    padding: 20px;
    padding-top: 0;
    margin: 0;
  }
  img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 60px;
      color: white;
      text-align: center;
      width: 100%;
      display: block;
      line-height: 1;
    }
  }
`
export const ButtonStyled = styled(Button)`
  width: 100%;
  min-height: 80px;
`

export const CardBodyStyled = styled(CardBody)`
  a {
    display: block;
    margin-bottom: 10px;
  }
`
export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`
export const QuoteItem = styled.div`
  padding: 20px;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 25px;
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    p {
      font-size: 15px;
      color: #666;
      font-style: italic;
    }
    span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 20px;
    }
  }
  button {
    max-width: 80px;
    align-self: flex-end;
  }
`
