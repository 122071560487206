import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import Alert from '@paljs/ui/Alert'
import Spinner from '@paljs/ui/Spinner'
import { InputGroup } from '@paljs/ui/Input'
import { Button } from '@paljs/ui/Button'

const Input = styled(InputGroup)`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`
const Label = styled.label`
  margin-bottom: 3px;
  font-weight: 500;
`
const ButtonGroup = styled.div`
  margin-top: 30px;
  float: right;
`
export interface PaymentData {
  paymentRate?: number
  address?: string
  paymentMethod?: string
}

export const validationSchema = Yup.object({
  paymentRate: Yup.number().positive().required('Payment Rate is required'),
  paymentMethod: Yup.string().required('Payment Method is required'),
  address: Yup.string().required('Address is required'),
})

export type OnSubmitProps = (values: PaymentData, formikHelpers: FormikHelpers<PaymentData>) => void

interface IndexProps {
  initialValues: PaymentData
  onSubmit: OnSubmitProps
  loading: boolean
}

const Index: React.FC<IndexProps> = ({ initialValues, onSubmit, loading }) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange={false}>
      {({ handleChange, handleSubmit, errors, values, setErrors }) => {
        return (
          <form method='post' onSubmit={handleSubmit}>
            <Input fullWidth size='Medium' status={!errors.paymentRate ? 'Basic' : 'Danger'}>
              <Label>Payment Rate</Label>
              <input
                type='number'
                name='paymentRate'
                value={values.paymentRate}
                onChange={handleChange}
                placeholder='Payment Rate'
                disabled={loading}
              />
            </Input>
            <Input fullWidth size='Medium' status={!errors.address ? 'Basic' : 'Danger'}>
              <Label>Address</Label>
              <textarea name='address' value={values.address} onChange={handleChange} placeholder='Address' disabled={loading} rows={3} />
            </Input>
            <Input fullWidth size='Medium' status={!errors.paymentMethod ? 'Basic' : 'Danger'}>
              <Label>Payment Method</Label>
              <textarea
                name='paymentMethod'
                value={values.paymentMethod}
                onChange={handleChange}
                placeholder='Payment Method'
                disabled={loading}
                rows={3}
              />
            </Input>

            {!!Object.keys(errors).length && (
              <Alert closable status='Danger' onClose={() => setErrors({})}>
                {Object.keys(errors).map((key) => (
                  <span key={key}>{errors[key]}</span>
                ))}
              </Alert>
            )}

            <ButtonGroup>
              <Button type='submit' appearance='hero' style={{ position: 'relative' }} disabled={loading}>
                Save Changes
                {loading && <Spinner status='Info' />}
              </Button>
            </ButtonGroup>
          </form>
        )
      }}
    </Formik>
  )
}

export default Index
