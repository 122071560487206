import styled from 'styled-components'
import { Card } from '@paljs/ui/Card'

import { stringToColor, isDark } from '../../util/colors'

export const TitleText = styled.h1`
  font-size: 30px;
  line-height: 1;
  height: 70px;
`
export const AuthorText = styled.span`
  float: left;
`
export const DateText = styled.span`
  margin-left: 10px;
`
export const TopicText = styled.span<{ name: string }>`
  float: right;
  margin-left: 10px;
  display: block;
  background-color: ${({ name }) => stringToColor(name)};
  padding: 5px;
  border-radius: 5px;
  color: ${({ name }) => (isDark(stringToColor(name)) ? '#fff' : '#000')};
`
export const CardStyled = styled(Card)`
  a {
    text-decoration: none;
    color: unset;
  }
  &:hover {
    opacity: 0.7;
  }
`
