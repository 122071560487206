import React from 'react'
import moment from 'moment'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from 'react-apollo'

import { EvaIcon } from '@paljs/ui/Icon'
import { Card, CardBody, CardHeader } from '@paljs/ui/Card'
import Tooltip from '@paljs/ui/Tooltip'
import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'

import { GET_FILE_RESOURCES } from '../../util/graphql/queries'

import Loading from '../../components/Loading'
import Pagination from '../../components/Pagination'

import Layout from '../../layout'

import { TitleText, AuthorText, DateText, DescriptionText, FilesContainer } from './styled'

const Index: React.FC<RouteComponentProps<{ page: string }>> = ({ page = '1' }) => {
  const pageNum = parseInt(page)
  const limit = 12
  const { data, loading } = useQuery(GET_FILE_RESOURCES, { variables: { start: (pageNum - 1) * limit, limit } })
  const totalCount = data?.fileResourcesConnection?.aggregate?.totalCount

  if (loading) return <Loading />
  return (
    <Layout seoTitle='Files'>
      {!data?.fileResources?.length && <h1>There are no items yet, :(</h1>}
      <Row>
        {data?.fileResources?.map((k, i) => (
          <Col breakPoint={{ xs: 12, md: 6, xxxl: 4 }} key={i}>
            <Card>
              <CardHeader>
                <TitleText>{k.title}</TitleText>
                <DescriptionText>{k.description}</DescriptionText>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <AuthorText>
                      <EvaIcon name='person' />
                      {`${k.created_by.firstname} ${k.created_by.lastname}`}
                    </AuthorText>
                  </Col>
                  <Col>
                    <EvaIcon name='calendar-outline' />
                    <DateText>{moment(k.updated_at).format('LL')}</DateText>
                  </Col>
                  <Col>
                    <FilesContainer>
                      {k.files.map((f, i) => (
                        <Tooltip key={i} trigger='hint' placement='bottom' content={f.name}>
                          <a href={f.url} target='__blank'>
                            {f.formats ? (
                              <img src={f.formats.thumbnail.url} />
                            ) : (
                              <div>
                                <span>{f.ext}</span>
                                <EvaIcon name='file' />
                              </div>
                            )}
                          </a>
                        </Tooltip>
                      ))}
                    </FilesContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        {!!data?.fileResources?.length && (
          <Col>
            <Pagination itemName='files' page={pageNum} pages={Math.ceil(totalCount / limit)} />
          </Col>
        )}
      </Row>
    </Layout>
  )
}

export default Index
