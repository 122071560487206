import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { Card, CardBody, CardHeader } from '@paljs/ui/Card'
import { Toastr, ToastrRef } from '@paljs/ui/Toastr'
import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'

import WrikeAuth from '../../components/Wrike/Auth'
import Loading from '../../components/Loading'

import Layout from '../../layout'

import ProfileForm, { OnSubmitProps } from '../../forms/ProfileForm'

import useAuth from '../../util/useAuth'
import * as api from '../../util/api'

const TitleCard = styled.div`
  font-size: 30px !important;
`
export interface UserData {
  email: string
  fullName: string
  password?: string
  passwordConfirm?: string
  paymentRate?: number
  address?: string
  paymentMethod?: string
}

const Profile: React.FC = () => {
  const { authState, getUser } = useAuth()
  const [data, setData] = useState({} as UserData)
  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const toastrRef = useRef<ToastrRef>(null)

  useEffect(() => {
    api
      .getUser(authState.jwt)
      .then((user) => {
        setData({
          email: user.data.email,
          fullName: user.data.fullName || '',
          password: '',
          passwordConfirm: '',
          paymentRate: user.data.paymentRate || '',
          paymentMethod: user.data.paymentMethod || '',
          address: user.data.address || '',
        })
      })
      .catch((error) => {
        console.error(error.message)
        navigate('/')
      })
      .finally(() => setLoading(false))
  }, [])

  const onSubmit: OnSubmitProps = ({ fullName, password }, actions) => {
    setSaveLoading(true)
    api
      .setUserProfile(authState.jwt, { fullName, password })
      .then(() => toastrRef.current.add('', 'Profile has been updated', { status: 'Success' }))
      .catch((error) => toastrRef.current.add(error.message, 'Uh-oh', { status: 'Danger' }))
      .finally(() => {
        actions.resetForm({
          values: {
            email: data.email,
            fullName,
            password: '',
            passwordConfirm: '',
          },
        })
        getUser()
        setSaveLoading(false)
      })
  }

  if (loading) return <Loading />
  return (
    <Layout seoTitle='Profile'>
      <Toastr ref={toastrRef} />
      <Row>
        <Col breakPoint={{ sm: 12, lg: 7 }}>
          <Card>
            <CardHeader>
              <TitleCard>Profile Information</TitleCard>
            </CardHeader>
            <CardBody>
              <ProfileForm initialValues={data} onSubmit={onSubmit} loading={saveLoading} />
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ sm: 12, lg: 5 }}>
          <WrikeAuth paymentData={data} />
        </Col>
      </Row>
    </Layout>
  )
}

export default Profile
