import styled from 'styled-components'
import { Card, CardHeader } from '@paljs/ui/Card'
import { Button } from '@paljs/ui/Button'

export const CardStyled = styled(Card)`
  max-width: 900px;
  margin: 0 auto;
`
export const CardHeaderStyled = styled(CardHeader)`
  h1 {
    font-size: 20px;
    padding-bottom: 0;
  }
  p {
    padding-top: 0;
    margin: 0;
  }
`
export const ButtonStyled = styled(Button)`
  width: 100%;
  min-height: 50px;
`
