import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from 'react-apollo'

import { EvaIcon } from '@paljs/ui/Icon'
import { CardBody, CardHeader } from '@paljs/ui/Card'
import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'

import { GET_POSTS } from '../../util/graphql/queries'

import Loading from '../../components/Loading'
import Pagination from '../../components/Pagination'

import Layout from '../../layout'

import { CardStyled, TitleText, AuthorText, TopicText, DateText } from './styled'

const Index: React.FC<RouteComponentProps<{ page: string }>> = ({ page = '1' }) => {
  const pageNum = parseInt(page)
  const limit = 12
  const { data, loading } = useQuery(GET_POSTS, { variables: { start: (pageNum - 1) * limit, limit } })
  const totalCount = data?.postsConnection?.aggregate?.totalCount

  if (loading) return <Loading />
  return (
    <Layout seoTitle='Posts'>
      {!data?.posts?.length && <h1>There are no items yet, :(</h1>}
      <Row>
        {data?.posts?.map((k, i) => (
          <Col breakPoint={{ xs: 12, md: 6, xxxl: 4 }} key={i}>
            <CardStyled>
              <Link to={`/post/${k.slug}`}>
                <CardHeader>
                  <TitleText>{k.title}</TitleText>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <AuthorText>
                        <EvaIcon name='person' />
                        {`${k.created_by.firstname} ${k.created_by.lastname}`}
                      </AuthorText>
                      <TopicText name={k.topic}>{k.topic}</TopicText>
                    </Col>
                    <Col>
                      <EvaIcon name='calendar-outline' />
                      <DateText>{moment(k.updated_at).format('LL')}</DateText>
                    </Col>
                  </Row>
                </CardBody>
              </Link>
            </CardStyled>
          </Col>
        ))}
      </Row>
      <Row>
        {!!data?.posts?.length && (
          <Col>
            <Pagination itemName='posts' page={pageNum} pages={Math.ceil(totalCount / limit)} />
          </Col>
        )}
      </Row>
    </Layout>
  )
}

export default Index
