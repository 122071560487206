import { Card } from '@paljs/ui/Card'
import styled from 'styled-components'

export const CardStyled = styled(Card)`
  min-height: 230px;
  display: flex;
`
export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  position: relative;
`
export const ImageContainer = styled.div`
  padding-top: 10px;
  height: 37px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`
export const Separator = styled.div`
  background-color: blue;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid #ddd;
`
export const TotalsContainer = styled.div`
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;

  h1 {
    font-size: 25px;
    line-height: 1.5;
    margin: 0;
    color: #666;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 20px;
    display: block;
    padding: 20px 0 20px 0;
    color: #333;
    span {
      font-weight: bold;
      font-size: 25px;
    }
  }
`
