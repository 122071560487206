import styled from 'styled-components'

export const PagContainer = styled.div`
  float: right;
  display: flex;
`
export const PagNumberGroup = styled.div`
  margin: 0 10px;
  a {
    margin: 0 2px;
  }
`
