import React, { useState } from 'react'
import moment from 'moment'

import { Radio } from '@paljs/ui/Radio'
import { Button } from '@paljs/ui/Button'
import Spinner from '@paljs/ui/Spinner'

import { RadioContainer, SelectStyled, TextStyled, Container } from './SearchStyled'

export type OnSearchProps = (
  result: { month: number; year: number; isCustom: boolean },
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => void

interface SearchProps {
  onSearch: OnSearchProps
}

const Index: React.FC<SearchProps> = ({ onSearch }) => {
  const [radioValue, setRadioValue] = useState(moment().format('MM-YYYY'))
  const [customSelect, setCustomSelect] = useState('')
  const [loading, setLoading] = useState(false)

  const onChangeRadio = (e: React.ReactText) => setRadioValue(e.toString())
  const onChangSelect = (e: { value: string }) => setCustomSelect(e.value)

  const radioOptions = React.useMemo(() => {
    const momentCustom = moment()
    return [
      { value: momentCustom.format('MM-YYYY'), label: momentCustom.format('MMMM'), checked: true },
      { value: momentCustom.add(-1, 'month').format('MM-YYYY'), label: momentCustom.format('MMMM'), checked: false },
      { value: 'custom', label: 'Custom', checked: false },
    ]
  }, [])

  const customOptions = React.useMemo(() => {
    const momentCustom = moment().add(-1, 'month')
    return Array(10)
      .fill('')
      .map(() => ({
        value: momentCustom.add(-1, 'month').format('MM-YYYY'),
        label: momentCustom.format('MMMM - YYYY'),
      }))
  }, [])

  const onButtonClick = () => {
    const selectedValue = radioValue !== 'custom' ? radioValue : customSelect ? customSelect : customOptions[0].value
    const month = parseInt(selectedValue.split('-')[0])
    const year = parseInt(selectedValue.split('-')[1])
    onSearch({ month, year, isCustom: radioValue === 'custom' }, setLoading)
  }

  return (
    <Container>
      <TextStyled>Select a month you want to get Wrike information:</TextStyled>
      <RadioContainer>
        <Radio name='radio' onChange={onChangeRadio} options={radioOptions} disabled={loading} />
        {radioValue === 'custom' && (
          <SelectStyled
            options={customOptions}
            defaultValue={customOptions[0]}
            onChange={onChangSelect}
            placeholder='Select a date'
            isDisabled={loading}
          />
        )}
        <Button size='Tiny' onClick={onButtonClick} disabled={loading} style={{ position: 'relative' }}>
          Generate It!
          {loading && <Spinner />}
        </Button>
      </RadioContainer>
    </Container>
  )
}

export default Index
