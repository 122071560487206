import styled from 'styled-components'
import { Card } from '@paljs/ui/Card'

export const TitleText = styled.h1`
  font-size: 20px;
  line-height: 1;
`
export const DescriptionText = styled.span`
  display: block;
  line-height: 1.3;
  margin-top: 10px;
  height: 50px;
  font-size: 13px;
  font-weight: normal;
`
export const AuthorText = styled.span`
  float: left;
`
export const DateText = styled.span`
  margin-left: 10px;
`

export const CardStyled = styled(Card)`
  min-height: 100px;
  a {
    text-decoration: none;
    color: unset;
  }
  &:hover {
    opacity: 0.7;
  }
`
export const ButtonContainer = styled.div`
  float: right;
`
