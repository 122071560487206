import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik, FormikHelpers, FormikProps } from 'formik'

import Col from '@paljs/ui/Col'
import Row from '@paljs/ui/Row'
import Alert from '@paljs/ui/Alert'
import Spinner from '@paljs/ui/Spinner'
import { InputGroup } from '@paljs/ui/Input'
import { Button } from '@paljs/ui/Button'

const Input = styled(InputGroup)`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`
const Label = styled.label`
  margin-bottom: 3px;
  font-weight: 500;
`
const ButtonGroup = styled.div`
  margin-top: 30px;
  float: right;
`

export const validationSchema = Yup.object({
  fullName: Yup.string().required('Full name is required'),
  password: Yup.string().min(6).notRequired(),
  passwordConfirm: Yup.string()
    .when('password', {
      is: (password: string) => password?.length > 0,
      then: Yup.string().required('Please confirm your password'),
    })
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export interface UserData {
  email: string
  fullName: string
  password?: string
  passwordConfirm?: string
}

export type OnSubmitProps = (values: UserData, formikHelpers: FormikHelpers<UserData>) => void

interface IndexProps {
  initialValues: UserData
  onSubmit: OnSubmitProps
  loading: boolean
}

type ProfileFormProps = FormikProps<UserData> & { loading: boolean }

const ProfileForm: React.FC<ProfileFormProps> = ({ loading, handleChange, handleSubmit, errors, values, setErrors }) => {
  return (
    <form method='post' onSubmit={handleSubmit}>
      <Input fullWidth size='Medium'>
        <Label>Email</Label>
        <input type='text' defaultValue={values.email} disabled />
      </Input>
      <Input fullWidth size='Medium' status={!errors.fullName ? 'Basic' : 'Danger'}>
        <Label>Full Name</Label>
        <input type='text' name='fullName' value={values.fullName} onChange={handleChange} placeholder='Full Name' disabled={loading} />
      </Input>
      <Row>
        <Col breakPoint={{ md: 12 }}>
          <p>Change password</p>
        </Col>
        <Col breakPoint={{ xs: 12, md: 6 }}>
          <Input fullWidth size='Medium' status={!errors.password ? 'Basic' : 'Danger'}>
            <input type='password' name='password' value={values.password} onChange={handleChange} placeholder='Password' disabled={loading} />
          </Input>
        </Col>
        <Col breakPoint={{ xs: 12, md: 6 }}>
          <Input fullWidth size='Medium' status={!errors.passwordConfirm ? 'Basic' : 'Danger'}>
            <input
              type='password'
              name='passwordConfirm'
              onChange={handleChange}
              value={values.passwordConfirm}
              placeholder='Password Confirmation'
              disabled={loading}
            />
          </Input>
        </Col>
      </Row>

      {!!Object.keys(errors).length && (
        <Alert closable status='Danger' onClose={() => setErrors({})}>
          {Object.keys(errors).map((key) => (
            <span key={key}>{errors[key]}</span>
          ))}
        </Alert>
      )}

      <ButtonGroup>
        <Button type='submit' appearance='hero' style={{ position: 'relative' }} disabled={loading}>
          Save Changes
          {loading && <Spinner status='Info' />}
        </Button>
      </ButtonGroup>
    </form>
  )
}

const Index: React.FC<IndexProps> = ({ initialValues, onSubmit, loading }) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange={false}>
      {(formikProps) => <ProfileForm {...formikProps} loading={loading} />}
    </Formik>
  )
}

export default Index
