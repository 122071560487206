import styled from 'styled-components'

import { stringToColor, isDark } from '../../util/colors'

export const GoBackText = styled.div<{ name: string }>`
  width: 100px;
  a {
    width: 100%;
    height: 30px;
    align-items: center;
    display: flex;
    padding: 5px;
    text-decoration: none;
    background-color: ${({ name }) => stringToColor(name)};
    color: ${({ name }) => (isDark(stringToColor(name)) ? '#fff' : '#000')};
    &:hover {
      opacity: 0.8;
      color: ${({ name }) => (isDark(stringToColor(name)) ? '#fff' : '#000')};
    }
  }
`

export const TitleText = styled.h1`
  font-size: 30px;
`
export const AuthorText = styled.span`
  float: left;
`
export const DateText = styled.span`
  margin-left: 10px;
`
export const TopicText = styled.span<{ name: string }>`
  float: right;
  margin-left: 10px;
  display: block;
  background-color: ${({ name }) => stringToColor(name)};
  padding: 5px;
  border-radius: 5px;
  color: ${({ name }) => (isDark(stringToColor(name)) ? '#fff' : '#000')};
`

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

export const ContentBox = styled.div`
  margin: 30px 0;
  padding-top: 20px;
  border-top: 1px solid #ccc;
`
